import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ExamOptionTable from "./components/ExamOptionTable";

import CEH_v10_AllQuestionsAnswers from "./pages/CEH_v10_AllQuestionsAnswers";
import AZ900_AllQuestionAnswers from "./pages/AZ900_AllQuestionAnswers";
import AZ400_AllQuestionAnswers from "./pages/AZ400_AllQuestionAnswers";
import AZ300_AllQuestionAnswers from "./pages/AZ300_AllQuestionAnswers";
import AZ100_AllQuestionAnswers from "./pages/AZ100_AllQuestionAnswers";
import ADM201_AllQuestionAnswers from "./pages/ADM201_AllQuestionAnswers";
import SAAC01_AllQuestionAnswers from "./pages/SAAC01_AllQuestionAnswers";
import SAAC02_AllQuestionAnswers from "./pages/SAAC02_AllQuestionAnswers";
import CLFC01_AllQuestionAnswers from "./pages/CLFC01_AllQuestionAnswers";

import CEH_v10_PracticePage from "./pages/CEH_v10_PracticeExamPage";
import CEH_v9_PracticePage from "./pages/CEH_v9_PracticeExamPage";
import CEH_v8_PracticePage from "./pages/CEH_v8_PracticeExamPage";
import AZ100_PracticePage from "./pages/AZ100_PracticeExamPage";
import AZ300_PracticePage from "./pages/AZ300_PracticeExamPage";
import AZ400_PracticePage from "./pages/AZ400_PracticeExamPage";
import AZ900_PracticePage from "./pages/AZ900_PracticeExamPage";

import CLFC01_PracticePage from "./pages/CLFC01_PracticeExamPage";
import SAAC01_PracticePage from "./pages/SAAC01_PracticeExamPage";
import SAAC02_PracticePage from "./pages/SAAC02_PracticeExamPage";
import ADM201_PracticePage from "./pages/ADM201_PracticeExamPage";

function App() {
  //alert(questions[2].question);
  return (
    <Router>
      <div className="App">
        <Switch className="App-body">
          <Route path="/" exact>
            <div className="App-body">
              <BrandHeader />
              {/* <ExamOptionTable /> */}
            </div>
          </Route>
          <Route path="/All-ADM-201">
            <BrandHeader />
            <ADM201_AllQuestionAnswers />
          </Route>
          <Route path="/All-SAA-C01">
            <BrandHeader />
            <SAAC01_AllQuestionAnswers />
          </Route>
          <Route path="/All-SAA-C02">
            <BrandHeader />
            <SAAC02_AllQuestionAnswers />
          </Route>
          <Route path="/All-CLF-C01">
            <BrandHeader />
            <CLFC01_AllQuestionAnswers />
          </Route>
          <Route path="/All-AZ-900">
            <BrandHeader />
            <AZ900_AllQuestionAnswers />
          </Route>
          <Route path="/All-AZ-400">
            <BrandHeader />
            <AZ400_AllQuestionAnswers />
          </Route>
          <Route path="/All-AZ-300">
            <BrandHeader />
            <AZ300_AllQuestionAnswers />
          </Route>
          <Route path="/All-AZ-100">
            <BrandHeader />
            <AZ100_AllQuestionAnswers />
          </Route>
          <Route path="/All-CEH">
            <BrandHeader />
            <CEH_v10_AllQuestionsAnswers />
          </Route>
          <Route path="/CEH_v10">
            <BrandHeader />
            <CEH_v10_PracticePage />
          </Route>
          <Route path="/CEH_v9">
            <BrandHeader />
            <CEH_v9_PracticePage />
          </Route>
          <Route path="/CEH_v8">
            <BrandHeader />
            <CEH_v8_PracticePage />
          </Route>
          <Route path="/AZ-100">
            <BrandHeader />
            <AZ100_PracticePage />
          </Route>
          <Route path="/AZ-300">
            <BrandHeader />
            <AZ300_PracticePage />
          </Route>
          <Route path="/AZ-400">
            <BrandHeader />
            <AZ400_PracticePage />
          </Route>
          <Route path="/AZ-900">
            <BrandHeader />
            <AZ900_PracticePage />
          </Route>
          <Route path="/CLF-C01">
            <BrandHeader />
            <CLFC01_PracticePage />
          </Route>
          <Route path="/SAA-C01">
            <BrandHeader />
            <SAAC01_PracticePage />
          </Route>
          <Route path="/SAA-C02">
            <BrandHeader />
            <SAAC02_PracticePage />
          </Route>
          <Route path="/ADM-201">
            <BrandHeader />
            <ADM201_PracticePage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

const BrandHeader = () => (
  <header className="App-header">
    {/* <img src={logo} className="App-logo-small" alt="logo" /> */}
    <p>Providing accurate, up to date exam questions of popular IT exams</p>
    <p>Learning Knowledge Brings Hope</p>
    <p>Practice Makes Perfect</p>
  </header>
);

export default App;
