import "../App.css";
import React from "react";
import questions from "../resources/questions_ADM201.json";

function ADM201_AllQuestionAnswers() {
  let questionBoxes = [];
  let selectionItems = [];
  let questionContentArr = [];

  for (var i = 0; i < questions.length; i++) {
    selectionItems = [];
    for (var j = 0; j < questions[i].selectionItems.length; j++) {
      // let ans = questions[i].answer;
      // let selectItem = questions[i].selectionItems[j].selectionItemID;

      selectionItems.push(
        <div>
          {" ("}
          {questions[i].selectionItems[j].selectionItemID}
          {") "}
          {questions[i].selectionItems[j].selectionItemContent}
        </div>
      );
    }
    if (questions[i].question.substring(0, 3) !== "NO.") {
      questions[i].question =
        "NO." + (i + 1).toString() + " " + questions[i].question;
    }
    questionContentArr = questions[i].question.split("\r\n");
    questionContentArr = questionContentArr.map((item) => (
      <div>
        {item}
        <br />
      </div>
    ));

    questionBoxes.push(
      <div className="Question-box">
        <div className="Question-text">{questionContentArr}</div>
        <div className="Question-option">{selectionItems}</div>
        <div className="Question-text">Answer : {questions[i].answer}</div>
      </div>
    );
  }

  return (
    <div>
      <h2>Practicing ADM-201: Administration Essentials for New Admins</h2>
      <div className="row">
        <div className="col-sm-10">{questionBoxes}</div>
        <div className="col-sm-2"></div>
      </div>
    </div>
  );
}

export default ADM201_AllQuestionAnswers;
