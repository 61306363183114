import "../App.css";
import React from "react";
import questions from "../resources/questions_CEHv9.json";
import QuestionBoxes from "../components/QuestionBox";

function CEH_v9_PracticeExamPage() {
  return (
    <div>
      <h2>
        Practicing CEH v9: EC-Council Certified Ethical Hacker Exam: 312-50
      </h2>
      <QuestionBoxes questions={shuffle(questions)} />
    </div>
  );
}

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default CEH_v9_PracticeExamPage;
