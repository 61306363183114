import React, { Component } from "react";
import logoCorrect from "../resources/CorrectIcon.png";
import logoWrong from "../resources/WrongIcon.png";
import ScoreBoard from "./ScoreBoard.jsx";
import RewardPage from "./RewardContentBox.jsx";

class QuestionBox extends Component {
  state = {
    currentQuestionIndex: 0,
    optionChecked: {},
    validationResult: {},
    correctCount: 0,
    incorrectCount: 0,
  };

  onAnswerValidation = (correctans, optionval, multiFlag) => {
    debugger;
    let validationResult = this.state.validationResult;
    let correctCount = 0;
    let incorrectCount = 0;

    if (!multiFlag && correctans === optionval) {
      validationResult[this.state.currentQuestionIndex] = true;
    } else if (!multiFlag && !(correctans === optionval)) {
      validationResult[this.state.currentQuestionIndex] = false;
    }

    //debugger;
    //刷新已勾選的選項
    let tmpOptionchecked = this.state.optionChecked;
    if (!multiFlag) {
      tmpOptionchecked[this.state.currentQuestionIndex] = optionval;
    } else if (
      multiFlag &&
      tmpOptionchecked[this.state.currentQuestionIndex] === undefined
    ) {
      tmpOptionchecked[this.state.currentQuestionIndex] = optionval;
      tmpOptionchecked[this.state.currentQuestionIndex] += ";";
    } else if (
      multiFlag &&
      tmpOptionchecked[this.state.currentQuestionIndex].indexOf(optionval) == -1
    ) {
      tmpOptionchecked[this.state.currentQuestionIndex] += optionval;
      tmpOptionchecked[this.state.currentQuestionIndex] += ";";
    } else if (
      multiFlag &&
      tmpOptionchecked[this.state.currentQuestionIndex].indexOf(optionval) > -1
    ) {
      tmpOptionchecked[this.state.currentQuestionIndex] = tmpOptionchecked[
        this.state.currentQuestionIndex
      ].replace(optionval + ";", "");
    }

    //debugger;
    //驗證多選題答案
    if (
      multiFlag &&
      (correctans + ";").split(";").sort().toString() ===
        tmpOptionchecked[this.state.currentQuestionIndex]
          .split(";")
          .sort()
          .toString()
    ) {
      validationResult[this.state.currentQuestionIndex] = true;
    } else if (
      multiFlag &&
      (correctans + ";").split(";").sort().toString() !==
        tmpOptionchecked[this.state.currentQuestionIndex]
          .split(";")
          .sort()
          .toString()
    ) {
      validationResult[this.state.currentQuestionIndex] = false;
    }

    for (let key in this.state.validationResult) {
      if (this.state.validationResult[key] === true) {
        correctCount++;
      } else if (this.state.validationResult[key] === false) {
        incorrectCount++;
      }
    }

    this.setState({
      currentQuestionIndex: this.state.currentQuestionIndex,
      optionChecked: tmpOptionchecked,
      validationResult: validationResult,
      correctCount: correctCount,
      incorrectCount: incorrectCount,
    });
  };

  onShowingNextQuestion = () => {
    let tmpID = 0;
    if (
      this.state.currentQuestionIndex <
      sessionStorage.getItem("NumberOfQuestion") - 1
    ) {
      tmpID = this.state.currentQuestionIndex + 1;
    } else {
      alert("There is No Other Questions");
      tmpID = this.state.currentQuestionIndex;
    }

    this.setState({
      currentQuestionIndex: tmpID,
    });
  };
  onShowingPreviousQuestion = () => {
    let tmpID = 0;
    if (this.state.currentQuestionIndex >= 1) {
      tmpID = this.state.currentQuestionIndex - 1;
    } else {
      alert("There is No Other Questions");
      tmpID = this.state.currentQuestionIndex;
    }

    this.setState({
      currentQuestionIndex: tmpID,
    });
  };

  render() {
    let questionBoxes = [];
    let selectionItems = [];
    let questionContentArr = [];

    for (var i = 0; i < this.props.questions.length; i++) {
      for (var j = 0; j < this.props.questions[i].selectionItems.length; j++) {
        let ans = this.props.questions[i].answer;
        let selectItem = this.props.questions[i].selectionItems[j]
          .selectionItemID;
        //單選題選項
        if (ans.split(";").length == 1) {
          //debugger;
          selectionItems.push(
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  name={this.props.questions[i].questionID}
                  checked={
                    this.state.optionChecked[i] ==
                    this.props.questions[i].selectionItems[j].selectionItemID
                  }
                  optionval={
                    this.props.questions[i].selectionItems[j].selectionItemID
                  }
                  correctans={this.props.questions[i].answer}
                  onChange={() => {
                    this.onAnswerValidation(ans, selectItem, false);
                  }}
                />
                {" ("}
                {this.props.questions[i].selectionItems[j].selectionItemID}
                {") "}
                {this.props.questions[i].selectionItems[j].selectionItemContent}
              </label>
            </div>
          );
        }
        //複選題選項
        if (ans.split(";").length > 1) {
          selectionItems.push(
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name={this.props.questions[i].questionID}
                  checked={
                    this.state.optionChecked[i] == undefined
                      ? false
                      : this.state.optionChecked[i].indexOf(
                          this.props.questions[i].selectionItems[j]
                            .selectionItemID
                        ) > -1
                  }
                  optionval={
                    this.props.questions[i].selectionItems[j].selectionItemID
                  }
                  correctans={this.props.questions[i].answer}
                  onChange={() => {
                    this.onAnswerValidation(ans, selectItem, true);
                  }}
                />
                {" ("}
                {this.props.questions[i].selectionItems[j].selectionItemID}
                {") "}
                {this.props.questions[i].selectionItems[j].selectionItemContent}
              </label>
            </div>
          );
        }
      }
      questionContentArr = this.props.questions[i].question.split("\r\n");
      questionContentArr = questionContentArr.map((item) => (
        <div>
          {item}
          <br />
        </div>
      ));

      let validationResult;

      if (this.state.validationResult[i]) {
        validationResult = <img width="60" src={logoCorrect}></img>;
      } else if (this.state.validationResult[i] !== undefined) {
        validationResult = <img width="60" src={logoWrong}></img>;
      }

      if (i === this.state.currentQuestionIndex) {
        questionBoxes.push(
          <div className="Question-box">
            <div className="Question-text">{questionContentArr}</div>
            <div className="Question-option">{selectionItems}</div>
            {validationResult}
          </div>
        );
      }

      selectionItems = [];
    }

    let scores = Math.round(
      (100 * this.state.correctCount) /
        sessionStorage.getItem("NumberOfQuestion")
    );
    if (
      this.state.currentQuestionIndex ===
        sessionStorage.getItem("NumberOfQuestion") - 1 &&
      scores >= 70
    ) {
      return (
        <center>
          <RewardPage />
        </center>
      );
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-sm-8">
              {questionBoxes}
              <button
                type="button"
                className="btn btn-success"
                onClick={this.onShowingPreviousQuestion}
              >
                Previous
              </button>
              &nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-success"
                onClick={this.onShowingNextQuestion}
              >
                Next
              </button>
            </div>
            <div className="col-sm-4">
              <ScoreBoard
                totalAmount={sessionStorage.getItem("NumberOfQuestion")}
                correctAmount={this.state.correctCount}
                incorrectAmount={this.state.incorrectCount}
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default QuestionBox;
