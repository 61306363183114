import React, { Component } from "react";
class ExamOptionTable extends Component {
  state = {};

  redirectPage = (pageId) => {
    if (sessionStorage.getItem("NumberOfQuestion") == undefined) {
      sessionStorage.setItem("NumberOfQuestion", 50);
    }

    if (pageId === "CEH_v10") document.location = "/CEH_v10";
    else if (pageId === "CEH_v9") document.location = "/CEH_v9";
    else if (pageId === "CEH_v8") document.location = "/CEH_v8";
    else if (pageId === "AZ-900") document.location = "/AZ-900";
    else if (pageId === "AZ-300") document.location = "/AZ-300";
    else if (pageId === "AZ-100") document.location = "/AZ-100";
    else if (pageId === "AZ-400") document.location = "/AZ-400";
    else if (pageId === "SAA-C01") document.location = "/SAA-C01";
    else if (pageId === "SAA-C02") document.location = "/SAA-C02";
    else if (pageId === "CLF-C01") document.location = "/CLF-C01";
    else if (pageId === "ADM-201") document.location = "/ADM-201";
  };

  onDropDownClick = (pageId, event) => {
    event.target.parentElement.parentElement.firstElementChild.innerText =
      event.target.innerText;
    sessionStorage.setItem(
      "NumberOfQuestion",
      event.target.getAttribute("value")
    );
  };

  render() {
    return (
      <div className="container">
        <br />
        <h3>4 Passing Exams</h3>
        <p>Please select the subject below to start practicing</p>
        <table className="table">
          <thead>
            <tr>
              <th>Subject</th>
              <th>Description</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Microsoft: AZ-100</td>
              <td>AZ-100 Microsoft Azure Infrastructure and Deployment</td>
              <td>
                <a
                  type="button"
                  href="/az100.html"
                  class="btn btn-outline-success"
                >
                  All AZ-100 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("AZ-100", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="112">
                      All -- 112
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("AZ-100")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>Microsoft: AZ-900</td>
              <td>AZ-900 Microsoft Azure Fundamentals</td>
              <td>
                <a
                  type="button"
                  href="/az900.html"
                  class="btn btn-outline-success"
                >
                  All AZ-900 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("AZ-900", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="130">
                      All -- 130
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("AZ-900")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>Microsoft: AZ-300</td>
              <td>AZ-300 Microsoft Azure Architect Technologies</td>
              <td>
                <a
                  type="button"
                  href="/az300.html"
                  class="btn btn-outline-success"
                >
                  All AZ-300 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("AZ-300", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="100">
                      100 -- Take Some Time
                    </li>
                    <li className="dropdown-item" value="150">
                      150 -- Practice More
                    </li>
                    <li className="dropdown-item" value="243">
                      All -- 243
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("AZ-300")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>Microsoft: AZ-400</td>
              <td>
                AZ-400 Designing and Implementing Microsoft DevOps Solutions
              </td>
              <td>
                <a
                  type="button"
                  href="/az400.html"
                  class="btn btn-outline-success"
                >
                  All AZ-400 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("AZ-400", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="90">
                      All -- 90
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("AZ-400")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>Amazon: SAA-C01</td>
              <td>SAA-C01 : AWS Certified Solutions Architect – Associate</td>
              <td>
                <a
                  type="button"
                  href="/az900.html"
                  class="btn btn-outline-success"
                >
                  All SAA-C01 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("SAAC01", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="345">
                      All -- Don't Miss Any Chance
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("SAA-C01")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>Amazon: SAA-C02</td>
              <td>SAA-C02 : AWS Certified Solutions Architect – Associate</td>
              <td>
                <a
                  type="button"
                  href="/az900.html"
                  class="btn btn-outline-success"
                >
                  All SAA-C02 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("SAAC02", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="65">
                      All -- Don't Miss Any Chance
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("SAA-C02")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>Amazon: CLF-C01</td>
              <td>CLF-C01 : AWS Certified Cloud Practitioner</td>
              <td>
                <a
                  type="button"
                  href="/az900.html"
                  class="btn btn-outline-success"
                >
                  All CLF-C01 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("CLFC01", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="374">
                      All -- Don't Miss Any Chance
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("CLF-C01")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>Salesforce: ADM-201</td>
              <td>ADM-201 : Administration Essentials for New Admins</td>
              <td>
                <a
                  type="button"
                  href="/adm201.html"
                  class="btn btn-outline-success"
                >
                  All ADM-201 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("ADM201", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="980">
                      All -- Don't Miss Any Chance
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("ADM-201")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>EC-Council: 312-50 v10</td>
              <td>CEH v10: EC-Council Certified Ethical Hacker Exam: 312-50</td>
              <td>
                <a
                  type="button"
                  href="/cehv10.html"
                  class="btn btn-outline-success"
                >
                  All CEH v10 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("CEH_v10", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="125">
                      125 -- Like the Real Exam
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("CEH_v10")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>EC-Council: 312-50 v9</td>
              <td>CEH v9: EC-Council Certified Ethical Hacker Exam: 312-50</td>
              <td>
                <a
                  type="button"
                  href="/cehv9.html"
                  class="btn btn-outline-success"
                >
                  All CEH v10 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("CEH_v10", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="125">
                      125 -- Like the Real Exam
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("CEH_v9")}
                >
                  Start
                </button>
              </td>
            </tr>
            <tr>
              <td>EC-Council: 312-50 v8</td>
              <td>CEH v8: EC-Council Certified Ethical Hacker Exam: 312-50</td>
              <td>
                <a
                  type="button"
                  href="/cehv9.html"
                  class="btn btn-outline-success"
                >
                  All CEH v8 Questions
                </a>
              </td>
              <td>
                <div className="dropdown">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Number of questions
                    <span className="caret"></span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    onClick={(event) => this.onDropDownClick("CEH_v10", event)}
                  >
                    <li className="dropdown-item" value="10">
                      10 -- Very Quick
                    </li>
                    <li className="dropdown-item" value="25">
                      25 -- Quick
                    </li>
                    <li className="dropdown-item" value="50">
                      50 -- Takes a While
                    </li>
                    <li className="dropdown-item" value="125">
                      125 -- Like the Real Exam
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => this.redirectPage("CEH_v8")}
                >
                  Start
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="card card-body bg-dark text-white">
          <h4>DISCLAIMER</h4>
          <ul>
            <li>
              CEH is owned by www.eccouncil.org and is not affiliated with
              4-passing-exams.com in any way. <br />
              Source:{" "}
              <a
                href="https://www.eccouncil.org/programs/certified-ethical-hacker-ceh/"
                target="_blank"
              >
                https://www.eccouncil.org/programs/certified-ethical-hacker-ceh/
              </a>
            </li>
            <li>
              Azure is owned by https://azure.microsoft.com/ and is not
              affiliated with 4-passing-exams.com in any way.
              <br />
              Source:{" "}
              <a href="https://azure.microsoft.com/en-us/" target="_blank">
                https://azure.microsoft.com/en-us/
              </a>
            </li>
            <li>
              AWS is owned by https://www.aws.training/ and is not affiliated
              with 4-passing-exams.com in any way.
              <br />
              Source:{" "}
              <a href="https://www.aws.training/" target="_blank">
                https://www.aws.training/
              </a>
            </li>
            <li>
              Salesforce is owned by https://www.salesforce.com/ and is not
              affiliated with 4-passing-exams.com in any way.
              <br />
              Source:{" "}
              <a href="https://www.salesforce.com/" target="_blank">
                https://www.salesforce.com/
              </a>
            </li>
          </ul>
        </div>
        <br></br>
      </div>
    );
  }
}

export default ExamOptionTable;
