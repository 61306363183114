import React, { Component } from "react";
class ScoreBoard extends Component {
  state = {
    totalAmount: this.props.totalAmount,
    correctAmount: this.props.correctAmount,
    incorrectAmount: this.props.incorrectAmount,
  };
  render() {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Correct</th>
            <th>Incorrect</th>
            <th>Gained Score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{this.props.correctAmount}</td>
            <td>{this.props.incorrectAmount}</td>
            <td>
              {Math.round(
                (100 * this.props.correctAmount) / this.props.totalAmount
              )}
              %
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default ScoreBoard;
