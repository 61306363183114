import React, { Component } from "react";
import rewardIcon from "../resources/CEH-loyalty-rewards.png";
class RewardContentBox extends Component {
  state = {};
  render() {
    return (
      <div className="RewardPage">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1> &nbsp;&nbsp;Congratulations ~!! </h1>
        <h1> &nbsp;&nbsp;You passed the simulation exam </h1>
        <img src={rewardIcon} />
        <br />
      </div>
    );
  }
}

export default RewardContentBox;
